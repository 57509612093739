<template>
  <v-dialog v-model="isModalOpen" persistent max-width="500px">
    <v-card>
      <v-card-title class="text-h3 font-weight-medium"
        >Confirm Order
      </v-card-title>
      <v-card-text
        >Is the {{ this.orderItem.tracking_number }} order accepted by the
        supplier ?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ma-2" @click="close()" color="indigo" text>
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          class="primary"
          :loading="isLoading"
          :disabled="isLoading"
          @click="confirm()"
          text
        >
          Confirm Order</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isModalOpen: Boolean,
    toggleModal: Function,
    orderItem: Object,
  },
  mounted: function () {
    this.currentOrderItem = { ...this.orderItem };
  },
  data() {
    return {
      supply_order: null,
      isLoading: false,
    };
  },
  methods: {
    close() {
      this.toggleModal();
    },
    async confirm() {
      try {
        this.isLoading = true;
        this.currentOrderItem.supply_order_status = "confirmed";

        await this.$store.dispatch(
          "supplyOrders/updateStatus",
          this.currentOrderItem,
          {
            supply_order_id: this.currentOrderItem.id,
          }
        );
        await this.$store.dispatch("supplyOrders/list", {
          supply_order_status: "sent",
        });
        this.toggleModal();
        this.$store.dispatch(
          "alerts/success",
          this.$t("Order Confirmed successfully")
        );
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>